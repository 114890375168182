import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FAQData from "../content/faqs/index.json"
import Faq from "../components/misc/faq"
import HeroSubpage from "../components/heroes/hero-subpage"

const FAQPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={FAQData[intl.locale].title}
             description={FAQData[intl.locale].text}
        />
        <HeroSubpage
            theme={`dark`}
            content={FAQData[intl.locale]}
        />
        <Faq content={FAQData[intl.locale]} />
    </Layout>
)

export default injectIntl(FAQPage)
