import React from 'react';
import { Container, Accordion, Card, Button } from "react-bootstrap"
import {injectIntl} from "gatsby-plugin-react-intl"

class FAQ extends React.Component {

  render() {
        return (

            <div className={`faq`}>
                <Container>
                    <Accordion>
                        {this.props.content.faq.map((content, i) => {
                            return (
                                <Card key={i} className={`faq-entry`}>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey={`faq-${i}`}>
                                            {content.title}
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={`faq-${i}`}>
                                        <Card.Body dangerouslySetInnerHTML={{__html: content.text}}></Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                </Container>
            </div>
        )
    }
}

export default injectIntl(FAQ)
